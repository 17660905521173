export type NotificationSubType =
  | PostsNotificationSubType
  | CommentsNotificationSubType
  | GroupMemberNotificationSubType
  | CommunityOwnerNotificationSubType
  | EventOwnerNotificationSubType
  | ExpirationNotificationSubType
  | ChatNotificationSubType
  | ReactionNotificationSubType
  | JobNotificationSubType;

export enum PostsNotificationSubType {
  newPostInGroupYouAreMember = 'newPostInGroupYouAreMember',
  newPostInGroupYouOwn = 'newPostInGroupYouOwn',
  newPostMatchingYourTags = 'newPostMatchingYourTags',
  taggedInAPost = 'taggedInAPost',
}

export enum CommentsNotificationSubType {
  newCommentInPostYouCommented = 'newCommentInPostYouCommented',
  newReplyToYourComment = 'newReplyToYourComment',
  newCommentInPostYouCreated = 'newCommentInPostYouCreated',
  taggedInAComment = 'taggedInAComment',
}

export enum GroupMemberNotificationSubType {
  invited = 'invited',
  acceptedRequest = 'acceptedRequest',
  rejectedRequest = 'rejectedRequest',
  kicked = 'kicked',
  promoted = 'promoted',
  newGroupMatchingYourTags = 'newGroupMatchingYourTags',
}

export enum EventOwnerNotificationSubType {
  attending = 'attending',
  maybe = 'maybe',
  declined = 'declined',
  interested = 'interested',
  requestedAttendance = 'requestedAttendance',
}

export enum CommunityOwnerNotificationSubType {
  requestedMembership = 'requestedMembership',
  acceptedInvite = 'acceptedInvite',
  rejectedInvite = 'rejectedInvite',
  memberLeft = 'memberLeft',
  memberJoined = 'memberJoined',
}

export enum ExpirationNotificationSubType {
  cvExpirationNotificationSubType = 'cvExpirationNotificationSubType',
  siteExpirationNotificationSubType = 'siteExpirationNotificationSubType',
  jobExpirationNotificationSubType = 'jobExpirationNotificationSubType',
}

export enum ChatNotificationSubType {
  newChatMessage = 'newChatMessageNotificationSubType',
}

export enum ReactionNotificationSubType {
  reactionPost = 'postReactionNotificationSubType',
  reactionComment = 'commentReactionNotificationSubType',
}

export enum JobNotificationSubType {
  newJobMatchingYourTags = 'newJobMatchingYourTags',
}
