import { Injectable } from '@angular/core';
import { hardcodedValues } from 'hardcodedValues';
import { Conversation } from '../ai/conversation';
import { Message } from '../ai/message';
import { AuthService } from './auth.service';
import { hardcodedValuesDK } from 'hardcodedValuesDK';

@Injectable({
  providedIn: 'root',
})
export class AIAPIService {
  conversations: Conversation[] = [
    {
      id: 'simon',
      name: 'Streaming openai',
      messages: [],
    },
    {
      id: '1',
      name: 'ChatGPT UI Example',
      messages: [
        {
          role: 'user',
          content: 'count from 1 to 10 in js',
        },
        {
          role: 'assistant',
          content: [
            '',
            'Here',
            "'s",
            ' an',
            ' example',
            ' of',
            ' how',
            ' you',
            ' can',
            ' implement',
            ' a',
            ' counter',
            ' in',
            ' Node',
            '.js',
            ' that',
            ' logs',
            ' out',
            ' numbers',
            ' from',
            ' ',
            '1',
            ' to',
            ' ',
            '10',
            ':\n\n',
            '```',
            'javascript',
            '\n',
            'let',
            ' count',
            ' =',
            ' ',
            '1',
            ';\n\n',
            'function',
            ' log',
            'Number',
            '()',
            ' {\n',
            ' ',
            ' console',
            '.log',
            '(count',
            ');\n',
            '  \n',
            ' ',
            ' if',
            ' (',
            'count',
            ' <',
            ' ',
            '10',
            ')',
            ' {\n',
            '   ',
            ' count',
            '++;\n',
            '   ',
            ' setTimeout',
            '(log',
            'Number',
            ',',
            ' ',
            '100',
            '0',
            ');',
            ' //',
            ' Log',
            ' the',
            ' next',
            ' number',
            ' after',
            ' ',
            '1',
            ' second',
            '\n',
            ' ',
            ' }\n',
            '}\n\n',
            'log',
            'Number',
            '();\n',
            '``',
            '`\n\n',
            'Explanation',
            ':\n\n',
            '-',
            ' We',
            ' initialize',
            ' a',
            ' variable',
            ' `',
            'count',
            '`',
            ' with',
            ' the',
            ' initial',
            ' value',
            ' of',
            ' ',
            '1',
            '.\n',
            '-',
            ' We',
            ' create',
            ' a',
            ' function',
            ' `',
            'log',
            'Number',
            '`',
            ' that',
            ' logs',
            ' the',
            ' current',
            ' value',
            ' of',
            ' `',
            'count',
            '`',
            ' and',
            ' checks',
            ' if',
            ' it',
            ' is',
            ' less',
            ' than',
            ' ',
            '10',
            '.\n',
            '-',
            ' If',
            ' `',
            'count',
            '`',
            ' is',
            ' less',
            ' than',
            ' ',
            '10',
            ',',
            ' we',
            ' increment',
            ' it',
            ' by',
            ' ',
            '1',
            ' and',
            ' schedule',
            ' the',
            ' next',
            ' call',
            ' to',
            ' `',
            'log',
            'Number',
            '`',
            ' after',
            ' a',
            ' delay',
            ' of',
            ' ',
            '1',
            ' second',
            ' using',
            ' `',
            'setTimeout',
            '`.\n',
            '-',
            ' The',
            ' process',
            ' continues',
            ' until',
            ' `',
            'count',
            '`',
            ' reaches',
            ' ',
            '10',
            ',',
            ' at',
            ' which',
            ' point',
            ' it',
            ' stops',
            ' calling',
            ' `',
            'log',
            'Number',
            '()`',
            '.\n\n',
            'When',
            ' you',
            ' run',
            ' this',
            ' code',
            ' in',
            ' Node',
            '.js',
            ',',
            ' it',
            ' will',
            ' log',
            ' numbers',
            ' from',
            ' ',
            '1',
            ' to',
            ' ',
            '10',
            ' with',
            ' a',
            ' delay',
            ' of',
            ' ',
            '1',
            ' second',
            ' between',
            ' each',
            ' log',
            '.',
            '',
          ].join(''),
        },
      ],
    },
    // {
    //   id: '2',
    //   name: 'name2',
    //   messages: [
    //     {
    //       role: 'user',
    //       content: 'I have built Academondo',
    //     },
    //     {
    //       role: 'assistant',
    //       content:
    //         'Here is an example of a counter implemented in Node.js that logs out numbers from 1 to 10:\n\n``` javascript let count = 0; function  counter() { count++; console.log(count); if (count < 10) { setTimeout(counter, 1000); } } counter(); ``` \n\nIn this code, a variable `count` is initialized to 0. The `counter` function is defined, which increments `count` by 1 and logs it to the console. If the value of `count` is less than 10, the `setTimeout` function is used to schedule another invocation of `counter` after a delay of 1000 milliseconds (1 second). This creates a loop that keeps incrementing and logging the value of `count` until it reaches 10.',
    //     },
    //   ],
    // },
  ];
  constructor(private authService: AuthService) {}

  getConversations() {
    return this.conversations;
  }

  getConversation(id: string) {
    return this.conversations.find((c) => c.id === id);
  }

  addMessage(id: string, message: Message) {
    console.log('this.conversations', this.conversations);

    if (id === 'new') {
      this.createConversation(message);
    } else {
      this.conversations.filter((c) => c.id === id)[0].messages.push(message);
    }
  }

  createConversation(message: Message) {
    const id = this.conversations.length + 1;
    this.conversations.push({
      id: id.toString(),
      name: message.content,
      messages: [message],
    });
  }

  getThemeById(chatId: string) {
    let theme;
    if (chatId === '5f23f1ab-6967-4760-b278-1d8b9ceab957') {
      theme = {
        button: {
          backgroundColor: '#34a344',
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: 'white',
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage: 'Mit navn er Freja, hvad kan jeg hjælpe dig med?',
          backgroundColor: '#ffffff',
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          poweredByTextColor: '#34a344', // Pink text color
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Freja',
          botMessage: {
            backgroundColor: '#E6E6E6',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc: '/assets/logos/AM.svg',
          },
          userMessage: {
            backgroundColor: '#34a344',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc:
              'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#34a344', // Pink send button color
          },
        },
      };
    }
    if (chatId === 'b46b1841-2219-45b2-86d0-645d7b578864') {
      theme = {
        button: {
          backgroundColor: '#34a344',
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: 'white',
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage:
            'Hej! Mit navn er Steen Hildebrandt, jeg står klar til at svare på spørgsmål om min bøger omhandlende bæredygtighed',
          backgroundColor: '#ffffff',
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          poweredByTextColor: '#34a344', // Pink text color
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Spørg Professor Steen Hildebrandt - ufærdig',
          botMessage: {
            backgroundColor: '#E6E6E6',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc: '/assets/logos/AM.svg',
          },
          userMessage: {
            backgroundColor: '#34a344',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc:
              'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValuesDK.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#34a344', // Pink send button color
          },
        },
      };
    }
    if (chatId === '5927b5f3-2b51-4459-bb14-682d971ec24b') {
      theme = {
        button: {
          backgroundColor: '#052d47',
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: 'white',
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage:
            'Jeg er din assistent til Loligo FAQ, og jeg vil besvare dem efter bedste evne.',
          backgroundColor: '#ffffff',
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Loligo FAQ',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc:
              'https://firebasestorage.googleapis.com/v0/b/ai-minds.appspot.com/o/aaaa%2Fimages.jpg?alt=media&token=7d5f0e81-2b28-4038-ae4d-ff4244ed6bb4',
          },
          userMessage: {
            backgroundColor: '#052d47',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc:
              'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#052d47',
          },
        },
      };
    }
    if (
      chatId === 'c6861c90-eb4a-4743-887b-69f55f131b5c' ||
      chatId === '58c56ab2-d15c-4bc6-990c-583b884b6a50'
    ) {
      theme = {
        button: {
          backgroundColor: '#00958b',
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: 'white',
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage:
            'Du kan stille mig spørgsmål om management, og jeg vil besvare dem. Efter hvert svar vil jeg stille dig et relateret spørgsmål. Finder du det svært at besvare, kan du blot gentage spørgsmålet til mig.',
          backgroundColor: '#ffffff',
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'AAMS QA',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc:
              'https://firebasestorage.googleapis.com/v0/b/ai-minds.appspot.com/o/aaaa%2Faams%20(1).png?alt=media&token=d4cf2e37-9c3a-4d0a-b1ae-a04624f15607',
          },
          userMessage: {
            backgroundColor: '#00958b',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc:
              'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#00958b',
          },
        },
      };
    }
    if (
      chatId === '5f68afd7-57db-4045-b9ee-350de4c7c32b' ||
      chatId === '7ff54050-4ec5-4875-8eeb-ef55a161cc2a'
    ) {
      theme = {
        button: {
          backgroundColor: '#00958b',
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: 'white',
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage:
            'Angiv et tema, indenfor management, og jeg udarbejder en quiz. Skriv A, B, C eller D baseret på det svar, du mener er korrekt. Ellers trykker du bare på nulstil og skriver et nyt emne for at starte forfra.',
          backgroundColor: '#ffffff',
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'AAMS Quiz',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc:
              'https://firebasestorage.googleapis.com/v0/b/ai-minds.appspot.com/o/aaaa%2Faams%20(1).png?alt=media&token=d4cf2e37-9c3a-4d0a-b1ae-a04624f15607',
          },
          userMessage: {
            backgroundColor: '#00958b',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc:
              'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#00958b',
          },
        },
      };
    }
    if (
      chatId === 'b08e9a9b-580e-4138-a9ee-7750904ce579' ||
      chatId === 'c1b3ca7e-1686-4a87-82d6-b37937e553c4'
    ) {
      theme = {
        button: {
          backgroundColor: '#00958b',
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: 'white',
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage:
            'Fortæl mig et kompleks emne, og jeg vil forklare det på en enkel måde.',
          backgroundColor: '#ffffff',
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'AAMS Forklar',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc:
              'https://firebasestorage.googleapis.com/v0/b/ai-minds.appspot.com/o/aaaa%2Faams%20(1).png?alt=media&token=d4cf2e37-9c3a-4d0a-b1ae-a04624f15607',
          },
          userMessage: {
            backgroundColor: '#00958b',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc:
              'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#00958b',
          },
        },
      };
    }
    if (
      chatId === '390cb2a4-80c4-4711-a604-081d43b76190' ||
      chatId === '86aa9009-d90c-4430-b46d-c691cc2fc139'
    ) {
      theme = {
        button: {
          backgroundColor: '#00958b',
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: 'white',
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage:
            'Jeg er ekspert i at analysere tekster. Jeg kommer med rettelser, forslag til forbedringer. Herunder kan du indsætte en tekst, jeg skal kigge på. Kopier gerne mellem 10-20 linjer ind ad gangen.',
          backgroundColor: '#ffffff',
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'AAMS Tekst Analyse',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc:
              'https://firebasestorage.googleapis.com/v0/b/ai-minds.appspot.com/o/aaaa%2Faams%20(1).png?alt=media&token=d4cf2e37-9c3a-4d0a-b1ae-a04624f15607',
          },
          userMessage: {
            backgroundColor: '#00958b',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc:
              'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#00958b',
          },
        },
      };
    }
    if (
      chatId === '84c5f11f-923c-45f5-8e5e-0338de534de1' ||
      chatId === '365583cb-668e-4bdf-a10b-475cc581f76c'
    ) {
      theme = {
        button: {
          backgroundColor: '#00958b',
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: 'white',
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage:
            'Jeg hjælper dig med at skrive opgaver. Giv mig et tema, og jeg viser dig, hvordan en opgave kan struktureres over dette tema. Jeg kommer også med konkrete eksempler på, hvordan temaet kan præsenteres i opgaven.',
          backgroundColor: '#ffffff',
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'AAMS Opgave',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc:
              'https://firebasestorage.googleapis.com/v0/b/ai-minds.appspot.com/o/aaaa%2Faams%20(1).png?alt=media&token=d4cf2e37-9c3a-4d0a-b1ae-a04624f15607',
          },
          userMessage: {
            backgroundColor: '#00958b',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc:
              'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#00958b',
          },
        },
      };
    }
    if (chatId === '30d9f58c-fd11-49cd-808b-397069b5b529') {
      const sendIconColor = '#0e194f';
      const mainColor = '#0e194f';
      const mainColorText = '#fff';
      theme = {
        button: {
          backgroundColor: mainColor, // background color of topbar.
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: mainColorText, // color of topbar.
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage: 'Jeg hjælper dig med at AutoCAD.',
          backgroundColor: '#ffffff', // background color of chat window.
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Autocad',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#000',
            showAvatar: true,
            avatarSrc: `https://firebasestorage.googleapis.com/v0/b/ai-minds.appspot.com/o/aaaa%2FDALL%C2%B7E%202023-11-18%2010.00.24%20-%20A%20round%20icon%20with%20a%20red%20capital%20letter%20'A'%20in%20the%20center%20on%20a%20white%20background%2C%20designed%20in%20high%20resolution%20to%20resemble%20an%208k%20image%20quality.png?alt=media&token=7e2e6e95-d386-4d4e-b7b7-2b945001b5cc`,
          },
          userMessage: {
            backgroundColor: mainColor,
            textColor: mainColorText,
            showAvatar: true,
            avatarSrc:
              this.authService.getCurrentUser() &&
              this.authService.getCurrentUser().photoURL
                ? this.authService.getCurrentUser().photoURL
                : 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: sendIconColor,
          },
        },
      };
    }
    if (chatId === 'b397d3ac-3488-4c76-8479-1fda75770db6') {
      const sendIconColor = '#252346';
      const mainColor = '#252346';
      const mainColorText = '#fff';
      theme = {
        button: {
          backgroundColor: mainColor, // background color of topbar.
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: mainColorText, // color of topbar.
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage: `
## Jeg kan hjælpe med to hovedopgaver:

### Opgave 1: Omregning af bunkerpriser
Jeg kan beregne prisforskellen mellem prisen per ton og prisen per kubikmeter inklusiv densitet. Her er nogle eksempler:

- **Eksempel 1a:** "563 USD cbm densitet på 85%"
- **Eksempel 1b:** "574 USD mts densitet på 87%"



### Opgave 2: Find den bedste leverandør
Jeg kan hjælpe med at finde den bedste leverandør for bunkers ved at tage højde for både prisen og betalingsfristen. Her er et eksempel:

- **Eksempel 2a:**  
"Leverandør 1 har en pris på X$ per mts og en betalingsfrist på Y dage.  
Leverandør 2 har en pris på X$ per mts og en betalingsfrist på Y dage.  
Vi har en rente på Z% i vores bank.  
Kunden har en betalingsfrist på A dage og ønsker at købe B mts."  

**Hvad vil du gerne have hjælp med?**
`,
          backgroundColor: '#ffffff', // background color of chat window.
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Bunker Holding - Trader helper',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#000',
            showAvatar: true,
            avatarSrc: `https://firebasestorage.googleapis.com/v0/b/academondo-demo.appspot.com/o/00000xxx%2Fbunker-holding.png?alt=media&token=544f5f13-58a3-46bd-9be3-8c42748b8a68`,
          },
          userMessage: {
            backgroundColor: mainColor,
            textColor: mainColorText,
            showAvatar: true,
            avatarSrc:
              this.authService.getCurrentUser() &&
              this.authService.getCurrentUser().photoURL
                ? this.authService.getCurrentUser().photoURL
                : 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: sendIconColor,
          },
        },
      };
    }
    if (chatId === 'b255250a-4363-4e25-89f3-7cd293bbe572') {
      const sendIconColor = '#0e194f';
      const mainColor = '#0e194f';
      const mainColorText = '#fff';
      theme = {
        button: {
          backgroundColor: mainColor, // background color of topbar.
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: mainColorText, // color of topbar.
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage:
            'Angiv et tema, indenfor autoCAD, og jeg udarbejder en quiz. Vælg A, B, C eller D baseret på det svar, du mener er korrekt. Du kan også trykke på "En mere" for at få et nyt spørgsmål. Ellers trykker du bare på nulstil og skriver et nyt emne for at starte forfra.',
          backgroundColor: '#ffffff', // background color of chat window.
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Autocad',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#000',
            showAvatar: true,
            avatarSrc: `https://firebasestorage.googleapis.com/v0/b/ai-minds.appspot.com/o/aaaa%2FDALL%C2%B7E%202023-11-18%2010.00.24%20-%20A%20round%20icon%20with%20a%20red%20capital%20letter%20'A'%20in%20the%20center%20on%20a%20white%20background%2C%20designed%20in%20high%20resolution%20to%20resemble%20an%208k%20image%20quality.png?alt=media&token=7e2e6e95-d386-4d4e-b7b7-2b945001b5cc`,
          },
          userMessage: {
            backgroundColor: mainColor,
            textColor: mainColorText,
            showAvatar: true,
            avatarSrc:
              this.authService.getCurrentUser() &&
              this.authService.getCurrentUser().photoURL
                ? this.authService.getCurrentUser().photoURL
                : 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: sendIconColor,
          },
        },
      };
    }
    if (chatId === 'de30b1d7-4a1c-4ccf-9148-968af20d73e9') {
      theme = {
        button: {
          backgroundColor: '#02588e',
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: 'white',
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage: 'Jeg kan hjælpe dig med matematik',
          backgroundColor: '#ffffff',
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Matematik',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc: `https://files.oaiusercontent.com/file-hMc3tpaX19tDCyH5fSRLdhy6?se=2123-10-20T07%3A24%3A21Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3D5ef6a0ed-5548-4dbc-a8cd-afc9ce891074.png&sig=QSNDFJDFCfWSUdaRfYtFAljpbkh6LMHItjsOYucZvsU%3D`,
          },
          userMessage: {
            backgroundColor: '#02588e',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc:
              'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#02588e',
          },
        },
      };
    }
    if (chatId === '9a9aa6e0-9303-4c7a-acf7-919ef670ec2c') {
      theme = {
        button: {
          backgroundColor: '#02588e',
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: 'white',
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage: 'Jeg kan hjælpe dig med naturfag',
          backgroundColor: '#ffffff',
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Naturfag',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc: `https://files.oaiusercontent.com/file-hMc3tpaX19tDCyH5fSRLdhy6?se=2123-10-20T07%3A24%3A21Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3D5ef6a0ed-5548-4dbc-a8cd-afc9ce891074.png&sig=QSNDFJDFCfWSUdaRfYtFAljpbkh6LMHItjsOYucZvsU%3D`,
          },
          userMessage: {
            backgroundColor: '#02588e',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc:
              'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#02588e',
          },
        },
      };
    }
    if (chatId === '72252268-00e9-49ed-ae2c-8495b79ca68c') {
      theme = {
        button: {
          backgroundColor: '#02588e',
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: 'white',
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage: 'Jeg kan hjælpe dig med alle fag',
          backgroundColor: '#ffffff',
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Læreren, der ved alt',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc: `https://files.oaiusercontent.com/file-hMc3tpaX19tDCyH5fSRLdhy6?se=2123-10-20T07%3A24%3A21Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3D5ef6a0ed-5548-4dbc-a8cd-afc9ce891074.png&sig=QSNDFJDFCfWSUdaRfYtFAljpbkh6LMHItjsOYucZvsU%3D`,
          },
          userMessage: {
            backgroundColor: '#02588e',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc:
              'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#02588e',
          },
        },
      };
    }
    if (chatId === 'cca899b2-a91e-4114-8c13-85f323b7c1f7') {
      theme = {
        button: {
          backgroundColor: '#02588e',
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: 'white',
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage: 'Jeg kan hjælpe dig med PASE',
          backgroundColor: '#ffffff',
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'PASE',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc: `https://files.oaiusercontent.com/file-hMc3tpaX19tDCyH5fSRLdhy6?se=2123-10-20T07%3A24%3A21Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3D5ef6a0ed-5548-4dbc-a8cd-afc9ce891074.png&sig=QSNDFJDFCfWSUdaRfYtFAljpbkh6LMHItjsOYucZvsU%3D`,
          },
          userMessage: {
            backgroundColor: '#02588e',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc:
              'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#02588e',
          },
        },
      };
    }
    if (
      chatId === '8592e685-835a-4c1e-8e1d-0e3d5dfefd34' ||
      chatId === 'f145e12c-c778-4e39-be1a-d832ee02c48c' ||
      chatId === 'a8a83191-8f09-47fe-925d-311892a6a5c2'
    ) {
      // LOXAM
      theme = {
        button: {
          backgroundColor: '#fb263b',
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: 'white',
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage:
            'Giv mig en liste af navnene på det ønskede materiel samt en leveringsadresse (husk postnummer).\n\nMin opgave er at:\nUdregne den samlede vægt.\nUdregne køreafstanden mellem nærmeste Loxam center og leveringsadressen.\nTil sidst finder jeg transportprisen.',
          backgroundColor: '#ffffff',
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Loxam transportpris beregner',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc:
              'https://medias.loxam.com/media/images/h0f/DK_rental_logo.png',
          },
          userMessage: {
            backgroundColor: '#fb263b',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc:
              'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            newChatText: 'Nyt spørgsmål',
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#fb263b',
          },
        },
      };
    }
    if (
      chatId === 'aba20408-6b46-407c-9515-22a191497fa2' ||
      chatId === '2dd3c45e-5199-4c93-b3a1-e5d995e3bd4f'
    ) {
      // Antibiotika
      theme = {
        button: {
          backgroundColor: '#002555',
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: 'white',
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage: `Jeg kan hjælpe dig med antibiotika. Jeg kan svare på spørgsmål om dosering, forholdsregler og behandlingsrekommandationer:
informationsmateriale kommer fra version 19 af "Antibiotika - dosering, forholdsregler og behandlingsrekommandationer - håndbog"
https://antibiotika.dk/ `,
          backgroundColor: '#ffffff',
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'vip.regionh.dk',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#303235',
            showAvatar: true,
            avatarSrc:
              'https://firebasestorage.googleapis.com/v0/b/academondo-demo.appspot.com/o/00000xxx%2FRegionH_maerke_FB.png?alt=media&token=0b154de7-9bc5-4511-875d-a1ccc675af41',
          },
          userMessage: {
            backgroundColor: '#002555',
            textColor: '#ffffff',
            showAvatar: true,
            avatarSrc:
              'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValuesDK.TypeYourMessageHere,
            newChatText: 'Nyt spørgsmål',
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: '#002555',
          },
        },
      };
    }
    if (chatId === '6d4844cb-33a2-45e9-99a9-d7d5ffcb0e79') {
      // VIBOCOLD
      const sendIconColor = '#142942';
      const mainColor = '#2976a8';
      const mainColorText = '#fff';
      theme = {
        button: {
          backgroundColor: mainColor, // background color of topbar.
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: mainColorText, // color of topbar.
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage:
            'Hej! Hvordan kan jeg hjælpe dig? Jeg kan svare på alt det du også finder på hjemmesiden.',
          backgroundColor: '#ffffff', // background color of chat window.
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'VIBOCOLD',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#000',
            showAvatar: true,
            avatarSrc:
              'https://www.vibocold.dk/Files/Images/Hjemmeside/Graphic/favicon.ico',
          },
          userMessage: {
            backgroundColor: mainColor,
            textColor: mainColorText,
            showAvatar: true,
            avatarSrc:
              this.authService.getCurrentUser() &&
              this.authService.getCurrentUser().photoURL
                ? this.authService.getCurrentUser().photoURL
                : 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValuesDK.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: sendIconColor,
          },
        },
      };
    }
    if (chatId === 'f3f30bd4-4753-4842-a03d-3b9d1c6e591f') {
      const sendIconColor = '#89be92';
      const mainColor = '#d8e9db';
      const mainColorText = '#000';
      theme = {
        button: {
          backgroundColor: mainColor, // background color of topbar.
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: mainColorText, // color of topbar.
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage:
            'Jeg kan hjælpe dig med at lave en sammenfatning af det kliniske indtryk i prosa. Hvis du blot indsætter dine noter uden personfølsomme oplysninger, så laver jeg en sammenfatning for dig.',
          backgroundColor: '#ffffff', // background color of chat window.
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Add Klinikken',
          botMessage: {
            backgroundColor: mainColor,
            textColor: mainColorText,
            showAvatar: true,
            avatarSrc: `https://firebasestorage.googleapis.com/v0/b/ai-minds.appspot.com/o/aaaa%2FADD-klinikken_dark.png?alt=media&token=24b0f7ec-e300-47bc-b62d-4cffd3e7b085`,
          },
          userMessage: {
            backgroundColor: mainColor,
            textColor: mainColorText,
            showAvatar: true,
            avatarSrc:
              this.authService.getCurrentUser() &&
              this.authService.getCurrentUser().photoURL
                ? this.authService.getCurrentUser().photoURL
                : 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValuesDK.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: sendIconColor,
          },
        },
      };
    }
    if (
      chatId === 'cf6e3c99-4bd2-445e-94ca-e410374a349a' ||
      chatId === '51c26202-4f77-44bb-b2c1-6663c2206a36' ||
      chatId === '4251ad26-d05c-4d08-9cb5-df05a2bcdf07' ||
      chatId === '6fc721fa-c6cd-4ddf-a2be-81e835d842e8'
    ) {
      const sendIconColor = '#89be92';
      const mainColor = '#d8e9db';
      const mainColorText = '#000';
      theme = {
        button: {
          backgroundColor: mainColor, // background color of topbar.
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: mainColorText, // color of topbar.
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage: 'Velkommen til ADD Klinikkens chatbot',
          backgroundColor: '#ffffff', // background color of chat window.
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Add Klinikken',
          botMessage: {
            backgroundColor: mainColor,
            textColor: mainColorText,
            showAvatar: true,
            avatarSrc: `https://firebasestorage.googleapis.com/v0/b/ai-minds.appspot.com/o/aaaa%2FADD-klinikken_dark.png?alt=media&token=24b0f7ec-e300-47bc-b62d-4cffd3e7b085`,
          },
          userMessage: {
            backgroundColor: mainColor,
            textColor: mainColorText,
            showAvatar: true,
            avatarSrc:
              this.authService.getCurrentUser() &&
              this.authService.getCurrentUser().photoURL
                ? this.authService.getCurrentUser().photoURL
                : 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValuesDK.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: sendIconColor,
          },
        },
      };
    }
    if (chatId === '7bb4a531-ba35-4c8e-841b-14717b039f9c') {
      const sendIconColor = '#1d39a9';
      const mainColor = '#1d39a9';
      const mainColorText = '#fff';
      theme = {
        button: {
          backgroundColor: mainColor, // background color of topbar.
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: mainColorText, // color of topbar.
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage: 'Stil mig spørgsmål om konkurrenceevne',
          backgroundColor: '#ffffff', // background color of chat window.
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Konkurrenceevne',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#000',
            showAvatar: true,
            avatarSrc: `https://firebasestorage.googleapis.com/v0/b/academondo-demo.appspot.com/o/00000xxx%2Fmercantec%20icon.png?alt=media&token=72e8e043-fb47-4d47-9682-88601810cee8`,
          },
          userMessage: {
            backgroundColor: mainColor,
            textColor: mainColorText,
            showAvatar: true,
            avatarSrc:
              this.authService.getCurrentUser() &&
              this.authService.getCurrentUser().photoURL
                ? this.authService.getCurrentUser().photoURL
                : 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValuesDK.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: sendIconColor,
          },
        },
      };
    }
    if (chatId === 'ea03b4cf-12a9-4b3e-a7e4-f2bd5f9e42d9') {
      const sendIconColor = '#1d39a9';
      const mainColor = '#1d39a9';
      const mainColorText = '#fff';
      theme = {
        button: {
          backgroundColor: mainColor, // background color of topbar.
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: mainColorText, // color of topbar.
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage:
            'Angiv et tema, indenfor konkurrenceevne, og jeg udarbejder en quiz. Vælg A, B, C eller D baseret på det svar, du mener er korrekt. Du kan også trykke på "En mere" for at få et nyt spørgsmål. Ellers trykker du bare på nulstil og skriver et nyt emne for at starte forfra.',
          backgroundColor: '#ffffff', // background color of chat window.
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Konkurrenceevne',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#000',
            showAvatar: true,
            avatarSrc: `https://firebasestorage.googleapis.com/v0/b/academondo-demo.appspot.com/o/00000xxx%2Fmercantec%20icon.png?alt=media&token=72e8e043-fb47-4d47-9682-88601810cee8`,
          },
          userMessage: {
            backgroundColor: mainColor,
            textColor: mainColorText,
            showAvatar: true,
            avatarSrc:
              this.authService.getCurrentUser() &&
              this.authService.getCurrentUser().photoURL
                ? this.authService.getCurrentUser().photoURL
                : 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValuesDK.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: sendIconColor,
          },
        },
      };
    }
    if (chatId === 'e085bfc8-6941-49e3-869a-0689156599f1') {
      const sendIconColor = 'var(--primary)';
      const mainColor = '#234F4F';
      const mainColorText = '#fff';
      theme = {
        button: {
          backgroundColor: mainColor, // background color of topbar.
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: mainColorText, // color of topbar.
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage: 'Velkommen til Greenlinks chatbot',
          backgroundColor: '#ffffff', // background color of chat window.
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'Greenlink',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#000',
            showAvatar: true,
            avatarSrc: `https://firebasestorage.googleapis.com/v0/b/sustainable-denmark.appspot.com/o/AM.svg?alt=media&token=c4fd6a33-8b08-48fc-acee-8522340dc293`,
          },
          userMessage: {
            backgroundColor: mainColor,
            textColor: mainColorText,
            showAvatar: true,
            avatarSrc:
              this.authService.getCurrentUser() &&
              this.authService.getCurrentUser().photoURL
                ? this.authService.getCurrentUser().photoURL
                : 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValuesDK.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: sendIconColor,
          },
        },
      };
    }
    // Freepay
    // if (chatId === '33a45ebb-5ef1-416b-9d6e-c79d80c2cc07') {
    //   const sendIconColor = '#09d373';
    //   const mainColor = '#09d373';
    //   const mainColorText = '#000';
    //   theme = {
    //     button: {
    //       backgroundColor: mainColor, // background color of topbar.
    //       right: 20,
    //       bottom: 20,
    //       size: 'medium',
    //       iconColor: mainColorText, // color of topbar.
    //       customIconSrc:
    //         'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
    //     },
    //     chatWindow: {
    //       welcomeMessage:
    //         'Velkommen til Freepays prissammenlignings chatbot. Kopier teksten fra din nuværende indløsningsaftale, så laver jeg en sammenligning.',
    //       backgroundColor: '#ffffff', // background color of chat window.
    //       height:
    //         'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
    //       // height: 700,
    //       // width: 400,
    //       poweredByTextColor: '#303235',
    //       poweredByLink: 'https://academondo.com',
    //       poweredByText: 'Academondo',
    //       title: 'Freepay',
    //       botMessage: {
    //         backgroundColor: '#f7f8ff',
    //         textColor: '#000',
    //         showAvatar: true,
    //         avatarSrc: `https://freepay.dk/Content/Img/Logo/FREEPAY-logo-card.svg`,
    //       },
    //       userMessage: {
    //         backgroundColor: mainColor,
    //         textColor: mainColorText,
    //         showAvatar: true,
    //         avatarSrc:
    //           this.authService.getCurrentUser() &&
    //           this.authService.getCurrentUser().photoURL
    //             ? this.authService.getCurrentUser().photoURL
    //             : 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
    //       },
    //       textInput: {
    //         placeholder: hardcodedValues.TypeYourMessageHere,
    //         backgroundColor: '#ffffff',
    //         textColor: '#303235',
    //         sendButtonColor: sendIconColor,
    //       },
    //     },
    //   };
    // }
    if (chatId === 'a1922f9f-1c31-4db1-8aa1-735ea6681592') {
      const sendIconColor = 'var(--primary)';
      const mainColor = '#D5EADC';
      const mainColorText = '#000';
      theme = {
        button: {
          backgroundColor: mainColor, // background color of topbar.
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: mainColorText, // color of topbar.
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage: 'Velkommen til din chatbot',
          backgroundColor: '#ffffff', // background color of chat window.
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'ChatbotTitle',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#000',
            showAvatar: true,
            avatarSrc: `https://firebasestorage.googleapis.com/v0/b/ai-minds.appspot.com/o/aaaa%2FADD-klinikken_dark.png?alt=media&token=24b0f7ec-e300-47bc-b62d-4cffd3e7b085`,
          },
          userMessage: {
            backgroundColor: mainColor,
            textColor: mainColorText,
            showAvatar: true,
            avatarSrc:
              this.authService.getCurrentUser() &&
              this.authService.getCurrentUser().photoURL
                ? this.authService.getCurrentUser().photoURL
                : 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: sendIconColor,
          },
        },
      };
    }
    if (chatId === 'template') {
      const sendIconColor = 'var(--primary)';
      const mainColor = '#D5EADC';
      const mainColorText = '#000';
      theme = {
        button: {
          backgroundColor: mainColor, // background color of topbar.
          right: 20,
          bottom: 20,
          size: 'medium',
          iconColor: mainColorText, // color of topbar.
          customIconSrc:
            'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
          welcomeMessage: 'Velkommen til din chatbot',
          backgroundColor: '#ffffff', // background color of chat window.
          height:
            'calc(var(--app-height, 100vh) - var(--navbar-height) - var(--gpt-sidebar-height))',
          // height: 700,
          // width: 400,
          poweredByTextColor: '#303235',
          poweredByLink: 'https://academondo.com',
          poweredByText: 'Academondo',
          title: 'ChatbotTitle',
          botMessage: {
            backgroundColor: '#f7f8ff',
            textColor: '#000',
            showAvatar: true,
            avatarSrc: `https://firebasestorage.googleapis.com/v0/b/ai-minds.appspot.com/o/aaaa%2FADD-klinikken_dark.png?alt=media&token=24b0f7ec-e300-47bc-b62d-4cffd3e7b085`,
          },
          userMessage: {
            backgroundColor: mainColor,
            textColor: mainColorText,
            showAvatar: true,
            avatarSrc:
              this.authService.getCurrentUser() &&
              this.authService.getCurrentUser().photoURL
                ? this.authService.getCurrentUser().photoURL
                : 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
          },
          textInput: {
            placeholder: hardcodedValues.TypeYourMessageHere,
            backgroundColor: '#ffffff',
            textColor: '#303235',
            sendButtonColor: sendIconColor,
          },
        },
      };
    }
    return theme;
  }
  getConfigById(chatId: string) {
    let config;
    if (chatId === '5f23f1ab-6967-4760-b278-1d8b9ceab957') {
      config = {
        question1: 'Hvem er Freja',
        question2: 'Fortæl om bæredygtighedsaksen',
        // question3: 'gamle mænd unge damer',
      };
    }
    if (chatId === '618f2672-6691-454c-b909-c98c02a411b1') {
      config = {
        question1:
          'Forklar kaosteori, fraktaler og komplekse tal som var jeg 9år',
        // question2: 'Animals',
        // question3: 'gamle mænd unge damer',
      };
    }
    if (chatId === '7ff54050-4ec5-4875-8eeb-ef55a161cc2a') {
      config = {
        question1: 'Risikomatrice',
        // question2: 'Er Jakob Ellemann-jensen stadig formand for Venstre?',
        // question3: 'gamle mænd unge damer',
      };
    }
    if (
      chatId === 'c6861c90-eb4a-4743-887b-69f55f131b5c' ||
      chatId === '58c56ab2-d15c-4bc6-990c-583b884b6a50'
    ) {
      config = {
        // question1: 'Hvad er Kvalitetsstyring?',
        // question2: 'RCM fremgangsmåden',
        // question3: 'Normative referencer',
      };
      // } else {
      //   config = {
      //     question1: 'hvad kan jeg spørge om?',
      //     // question2: 'WTF2?',
      //     // question3: 'sp. 3?',
      //   };
    }
    if (chatId === 'a8a83191-8f09-47fe-925d-311892a6a5c2') {
      config = {
        // question1:
        //   'jeg vil gerne bestille YANMAR SV17 og FALCON FS330Z leveret til Fyrvej 5, 7730',
        question2:
          'jeg vil gerne bestille YANMAR SV17 og FALCON FS330Z leveret til Holmevej 123, 8270',
      };
      // } else {
      //   config = {
      //     question1: 'hvad kan jeg spørge om?',
      //     // question2: 'WTF2?',
      //     // question3: 'sp. 3?',
      //   };
    }
    if (chatId === '30d9f58c-fd11-49cd-808b-397069b5b529') {
      config = {
        question1: 'Jeg har brug for hjælp til at lave en tagkontruktion',
        question2:
          'Kan du ikke lave nogle nemme øvelser, så jeg kan øve mig i brugen af Autocad',
        // question3: '',
      };
      // } else {
      //   config = {
      //     question1: 'hvad kan jeg spørge om?',
      //     // question2: 'WTF2?',
      //     // question3: 'sp. 3?',
      //   };
    }
    if (chatId === 'de30b1d7-4a1c-4ccf-9148-968af20d73e9') {
      config = {
        question1: 'Lær mig om eksponentielle funktioner',
        question2:
          'Kan du ikke lave nogle nemme øvelser og retvinklede trekanter',
        // question3: '',
      };
      // } else {
      //   config = {
      //     question1: 'hvad kan jeg spørge om?',
      //     // question2: 'WTF2?',
      //     // question3: 'sp. 3?',
      //   };
    }
    if (chatId === '9a9aa6e0-9303-4c7a-acf7-919ef670ec2c') {
      config = {
        // question1: 'Lær mig om Pythagoras',
        question2: 'Naturfag på niveau G, hvad er mit pensum?',
        // question3: '',
      };
      // } else {
      //   config = {
      //     question1: 'hvad kan jeg spørge om?',
      //     // question2: 'WTF2?',
      //     // question3: 'sp. 3?',
      //   };
    }
    if (chatId === 'cca899b2-a91e-4114-8c13-85f323b7c1f7') {
      config = {
        // question1: 'Lær mig om Pythagoras',
        question2: 'Hvad er min læringsmål i PASE?',
        // question3: '',
      };
      // } else {
      //   config = {
      //     question1: 'hvad kan jeg spørge om?',
      //     // question2: 'WTF2?',
      //     // question3: 'sp. 3?',
      //   };
    }
    if (chatId === 'aba20408-6b46-407c-9515-22a191497fa2') {
      config = {
        question1: 'Tussis convulsiva',
        question2: 'Purulent meningitis',
        question3: 'Osteomyelitis',
      };
      // } else {
      //   config = {
      //     question1: 'hvad kan jeg spørge om?',
      //     // question2: 'WTF2?',
      //     // question3: 'sp. 3?',
      //   };
    }
    if (
      chatId === 'b255250a-4363-4e25-89f3-7cd293bbe572' ||
      chatId === 'ea03b4cf-12a9-4b3e-a7e4-f2bd5f9e42d9' ||
      chatId === '5f68afd7-57db-4045-b9ee-350de4c7c32b'
    ) {
      config = {
        question1: 'A',
        question2: 'B',
        question3: 'C',
        question4: 'D',
        question5: 'En mere',
      };
      // } else {
      //   config = {
      //     question1: 'hvad kan jeg spørge om?',
      //     // question2: 'WTF2?',
      //     // question3: 'sp. 3?',
      //   };
    }
    return config;
  }
}
