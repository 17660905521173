import { Injectable } from '@angular/core';
import { DataConstants } from 'app/shared/consts/dataConstants';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { Observable, of, Subject } from 'rxjs';
import { DAO } from 'app/shared-services/db-access/dao';
import { HelperService } from 'app/core/helper.service';
import { NotificationData } from '../models/notification-data';
import { NotificationType } from '../models/notification-type';
import { GroupMemberNotificationSubType } from '../models/notification-sub-type';
import { AuthService } from 'app/core/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  public messaging: firebase.messaging.Messaging;
  private messageSource = new Subject();
  currentMessage = this.messageSource.asObservable();

  constructor(
    private dao: DAO,
    private authService: AuthService,
    private fnsHelper: HelperService
  ) {
    if (firebase.messaging.isSupported()) {
      this.messaging = firebase.messaging();
    } else {
      console.error(`This browser doesn't support web push notifications.`);
    }
  }

  sendCloudMessageFN = this.fnsHelper.createFunctionPromise<
    { notificationData: NotificationData },
    any
  >('sendCloudMessageOnCall');

  private updateToken(token: string) {
    const user = this.authService.getCurrentUser();
    if (!user) {
      return;
    }
    const data = { [user.uid]: token };
    return this.dao.object(DataConstants.NOTIFICATIONS_TOKENS).update(data);
  }

  async removeToken() {
    return this.dao
      .object(
        DataConstants.NOTIFICATIONS_TOKENS +
          this.authService.getCurrentUser().uid
      )
      .remove();
  }

  fcmToken$() {
    if (this.messaging) {
      return this.dao
        .object(
          DataConstants.NOTIFICATIONS_TOKENS +
            this.authService.getCurrentUser().uid
        )
        .valueChanges() as Observable<string>;
    } else {
      return of('');
    }
  }

  async getPermission() {
    if (this.messaging) {
      return Notification.requestPermission()
        .then((permissionRequested) => {
          // console.log('Notification permission: ', permissionRequested);
          return this.messaging.getToken();
        })
        .then((token) => {
          this.updateToken(token);
          return true;
        })
        .catch((err) => {
          // console.error('Unable to get permission to send push messages.', err);
          this.removeToken();
          return false;
        });
    }
  }

  receiveMessage() {
    if (this.messaging) {
      this.messaging.onMessage((payload) => {
        // console.log('Message received. ', payload);
        this.playNotificationSound(
          payload.notification,
          'assets/audio/push-notification.mp3'
        );
        this.messageSource.next(payload);
      });
    }
  }

  playNotificationSound(notification, soundUrl?: string, vibrate = false) {
    const { title, body, imageUrl } = notification;
    if (soundUrl) {
      if (vibrate) {
        this.vibrate([100, 200, 100]);
      }
      const audio = new Audio();
      audio.src = soundUrl;
      audio.load();
      audio
        .play()
        .then(() =>
          this.openSnackBar(
            `${title} ${body}`,
            imageUrl,
            '',
            'aca-push-notification',
            7000
          )
        );
    } else {
      this.openSnackBar(
        `${title}: ${body}`,
        imageUrl,
        '',
        'aca-push-notification',
        7000
      );
    }
  }

  vibrate(pattern: VibratePattern = 100) {
    if (!window) {
      return;
    }

    if (!window.navigator) {
      return;
    }

    if (!window.navigator.vibrate) {
      return;
    }

    window.navigator.vibrate(pattern);
  }

  openSnackBar(
    message: string,
    image: string,
    action: string,
    className = '',
    duration = 1000
  ) {
    this.authService.imgSnackbar(message, image, '', duration, className);
  }

  spamSimon(userId: string) {
    const notificationData = {
      title: 'Velkommen til Push!',
      message: 'Hej Simon din gamle svinger',
      fromUserId: userId,
      toUserId: 'TdO9Vjh3sjS0wbWqaDmtI1McEjC2',
      link: `/_communities/-MdJr7pcaF41ammYFtSN/about`,
      notificationType: NotificationType.community,
      notificationSubType: GroupMemberNotificationSubType.invited,
      extraData: {
        groupId: '-MdJr7pcaF41ammYFtSN',
        groupType: '_communities',
      },
    } as NotificationData;
    // console.log(notificationData);

    return (
      this.sendCloudMessageFN({ notificationData: notificationData })
        // .then((response) => console.log(response))
        .catch((err) => {
          if (err && err.statusCode === 409) {
            Promise.resolve('account already created');
          } else {
            Promise.reject(`${err.statusCode} error: ${JSON.stringify(err)}`);
          }
        })
    );
  }
}
